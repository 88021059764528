import { createRouter, createWebHashHistory } from 'vue-router'
import Layout from '@/layout'

export const constantRoutes = [
  {
    path: '/login',
    component: () => import('@/views/login'),
    hidden: true,
  },
  {
    path: '/403',
    name: '403',
    component: () => import('@/views/403'),
    hidden: true,
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404'),
    hidden: true,
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404'),
    hidden: true,
  },
]
export const asyncRoutes = [
  {
    path: '/',
    component: Layout,
    redirect: '/index',
    meta: {
      title: '首页',
      icon: 'home',
      affix: true,
    },
    children: [
      {
        path: 'index',
        name: 'Index',
        component: () => import('@/views/index'),
        meta: {
          title: '首页',
          icon: 'home',
          affix: true,
        },
      },
    ],
  },
  {
    path: '/answer',
    component: Layout,
    redirect: '/answer/index',
    meta: {
      title: '答题管理',
      icon: 'measure',
    },
    children: [
      {
        path: 'index',
        name: 'Answer',
        component: () => import('@/views/answer/index'),
        meta: {
          title: '答题管理',
          icon: 'subject',
        },
      },
      {
        path: 'detail',
        name: 'AnswerDetail',
        hidden: true,
        component: () => import('@/views/answer/detail'),
        meta: {
          title: '答题管理-详情',
          icon: 'subject',
        },
      },
    ],
  },
  {
    path: '/featureClickNum',
    name: 'Error',
    component: Layout,
    redirect: '/featureClickNum/index',
    meta: {
      title: '特征点击量',
      icon: 'measure',
    },
    children: [
      {
        path: 'index',
        name: 'featureClickNum',
        component: () => import('@/views/featureClickNum/index'),
        meta: {
          title: '特征点击量',
          icon: 'measure',
        },
      },
    ],
  },
  // {
  //   path: '/error',
  //   name: 'Error',
  //   component: Layout,
  //   redirect: '/error/403',
  //   meta: {
  //     title: '特征点击量',
  //     icon: 'measure',
  //   },
  //   children: [
  //     {
  //       path: '403',
  //       name: 'Error403',
  //       component: () => import('@/views/403'),
  //       meta: {
  //         title: '403',
  //         icon: 'measure',
  //       },
  //     },
  //     {
  //       path: '404',
  //       name: 'Error404',
  //       component: () => import('@/views/404'),
  //       meta: {
  //         title: '404',
  //         icon: 'measure',
  //       },
  //     },
  //   ],
  // },
  {
    path: '/*',
    redirect: '/404',
    hidden: true,
  },
]
const router = createRouter({
  history: createWebHashHistory(),
  routes: constantRoutes,
})

export default router
