<template>
  <div class="vab-logo">
    <img src="@/assets/img/logo.png" alt="">
    <span class="anticon"></span>
    <span>{{ title }}</span>
  </div>
</template>

<script>
  import { computed } from 'vue'
  import { useStore } from 'vuex'

  export default {
    name: 'VabLogo',
    components: {},
    setup() {
      const store = useStore()
      return {
        logo: computed(() => store.getters['settings/logo']),
        title: computed(() => store.getters['settings/title']),
      }
    },
  }
</script>
<style lang="less" scoped>
  .vab-logo {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    height: 85px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #fff;
    font-family: Source Han Sans CN, Source Han Sans CN;
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
    padding-top: 5px;
    margin-bottom: 25px;

    img {
      width: 40px;
      height: 40px;
    }
  }
</style>